var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home",style:({ '--vh': _vm.vh + 'px' })},[_c('navHeader',{attrs:{"index":-1}}),_c('header',{class:_vm.isShowAnimation ? 'relative' : ''}),_c('div',{staticClass:"content",attrs:{"id":"content"}},[_c('div',{staticClass:"cover-wrapper",style:({ paddingBottom: _vm.getpbottom + 'px' })},[_c('div',{staticClass:"cover-container",class:_vm.isFixed && _vm.isShowAnimation ? 'flex' : '',style:({
          transform: 'translate3d(' + '0px' + ',' + (_vm.isShowAnimation ? 0 : _vm.getpbottom - _vm.vh) + 'px' + ',' + '0px)'
        })},[_c('div',{staticClass:"cover-box",style:({ zIndex: '4', height: '100%' })},[_c('div',{staticClass:"detail"},[_vm._m(0),_c('div',{staticClass:"cover-text",style:({
                transform: 'translate3d(' + '0px' + ',' + _vm.textBoxTranslate1 + 'px' + ',' + '0px)'
              })},[_vm._v(" 111数字果园以大数据、云计算、物联网等新一代信息技术为支撑，深度融合了种植、养殖、加工等诸多环节的数据管理与应用，优化农业生产过程，提高资源利用效率，实现精准农业、高效农业、可持续农业。 ")])])]),_c('div',{staticClass:"cover-box",style:({ zIndex: '3', height: '100%' })},[_c('div',{staticClass:"detail"},[_vm._m(1),_c('div',{staticClass:"cover-text textTranslate",style:({
                transform: 'translate3d(' + '0px' + ',' + _vm.textBoxTranslate2 + 'px' + ',' + '0px)'
              })},[_vm._v(" 222数字薄膜大棚作为一种新兴的农业形态，随着城市规划对绿地面积的限制和生产能力的提高，其逐渐被越来越多的人所接受和运用。其通过设备自动控制、信息互联等手段，实现了农业生产的数字化和智能化，同时也提升了农业生产的效率和质量。 ")])])])])])]),_c('footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('picture',[_c('img',{staticClass:"cover-pic",attrs:{"src":"https://img1.baidu.com/it/u=1865282318,2874149192&fm=253&fmt=auto&app=138&f=JPEG?w=800&h=500","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('picture',[_c('img',{staticClass:"cover-pic",attrs:{"src":"https://img1.baidu.com/it/u=1614235705,3930371442&fm=253&fmt=auto&app=120&f=JPEG?w=835&h=500","alt":""}})])
}]

export { render, staticRenderFns }