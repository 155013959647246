<template>
  <div class="navHeaderWrapper">
    <div class="navHeader">
      <div class="mainmenu">
        <div class="leftMenuList" v-if="!showMain">
          <div class="leftMenuItem" v-for="(mitem, mindex) in menuList" :key="mindex">
            <div
              class="leftMenuItemTitle"
              :style="mindex === index ? 'border-bottom: 1px solid #F8D86A;' : ''"
              @click="clickInput(mitem, mindex)"
            >
              {{ mitem.title }}
            </div>
          </div>
        </div>
        <div class="leftMenuList" v-else>
          <div class="leftMenuItem" v-for="(mitem, mindex) in menuList" :key="mindex">
            <div
              class="leftMenuItemTitle"
              :style="fakeIndex === mindex ? 'border-bottom: 1px solid #F8D86A;' : ''"
              @click="clickFakeInput(mitem, mindex)"
            >
              {{ mitem.title }}
            </div>
          </div>
        </div>

        <div class="leftMenuList-m">
          <i class="iconfont icon-ic_hamberger" @click="openModalm()" v-if="!openflagm"></i>
          <i class="iconfont icon-btn_close1" @click="openModalm()" v-else></i>
        </div>
        <div class="logoBox" @click="clickLogo()" v-if="!showMain">
          <img class="logo-light" :src="require(`@/assets/logo_sunyung.png`)" alt="耘尚农场" />
        </div>
        <div class="logoBoxFake" v-else></div>
        <div class="rightMenuList">
          <!-- <div class="rightMenuItem">
            <div class="rightMenuItemTitle">耘尚小程序</div>
            <div class="qrCodeBox">
              <img class="" :src="require(`@/assets/images/commonUse/qrCode4.png`)" alt="耘尚农场小程序二维码" />
            </div>
          </div> -->
          <div class="rightMenuItem">
            <div class="rightMenuItemTitle" @click="goShop()">线上商城</div>
          </div>
        </div>
        <i class="iconfont icon-btn_close1 closePosition1" @click="closeModal()" v-if="showMain"></i>
      </div>
    </div>
    <div class="submenuMain" v-if="showSub">
      <div class="submenuLeft">
        <div class="submenuItemBox">
          <div
            v-for="(citem, cindex) in submenuList"
            :key="cindex"
            @click="clickChildrenInput(citem)"
            class="submenuItem"
            :class="citem.idx === idx ? 'active' : ''"
            @mouseover="onHover(citem.picIndex, cindex)"
          >
            <i class="iconfont" :class="citem.iconfont"></i>
            <!-- <i class="iconfont" :class="citem.iconfont" v-if="citem.route === '/solution'"></i> -->
            <!-- <div class="submenuItemIn" v-else>{{ citem.title }}</div> -->
          </div>
        </div>
      </div>
      <div class="submenuRight">
        <img v-if="hoverIndex !== -1" :src="hoverImageList[hoverIndex]" alt="" />
      </div>
    </div>
    <div class="submenuMain-m" v-show="openflagm" @touchmove.prevent>
      <div class="header-menu">
        <div class="header-menu-item" v-for="(mitem, mindex) in menuList" :key="mindex">
          <div class="header-menu-item-inner" @click.prevent="openMenuChildren(mindex)">
            <div class="menuBigTitle">{{ mitem.title }}</div>
          </div>
          <div class="header-menu-item-sub-items" v-if="mitem.children" :class="mindex === collapseIndex ? '' : 'hide'">
            <div
              class="header-menu-sub-item"
              v-for="(item, index) in mitem.children"
              :key="index"
              @click.prevent="goChildren(item)"
            >
              <!-- <div class="header-menu-sub-item-text" :class="item.idx === idx ? 'active' : ''">{{ item.title }}</div> -->
              <!-- <div
                class="header-menu-sub-item-text"
                :class="item.idx === idx ? 'active' : ''"
                v-if="item.route === '/solution'"
              >
                <i class="iconfont" :class="item.iconfont"></i>
              </div>

              <div
                v-else
                class="header-menu-sub-item-text"
                :class="item.idx === idx ? 'active' : ''"
                style="font-weight: 900"
              >
                {{ item.title }}
              </div> -->
              <div class="header-menu-sub-item-text" :class="item.idx === idx ? 'active' : ''">
                <i class="iconfont" :class="item.iconfont"></i>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="header-menu-item">
          <div class="header-menu-item-inner" @click.prevent="openYunshang()">
            <div class="menuBigTitle">耘尚小程序</div>
          </div>
        </div> -->
        <div class="header-menu-item">
          <div class="header-menu-item-inner" @click.prevent="openTaobao()">
            <div class="menuBigTitle">线上商城</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'navHeader',
  props: {
    index: {
      type: Number,
      default: 0
    },
    childIndex: {
      type: Number,
      default: 0
    },
    childIndex0: {
      type: Number,
      default: 0
    },

    idx: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      hoverIndex: 0,
      showMain: false,
      showSub: false,
      fakeIndex: -1,
      submenuList: [],
      menuList: [
        { name: 'home', title: '首页', route: '/' },
        {
          name: 'solution',
          title: '解决方案',
          route: '',
          children: [
            {
              idx: 1,
              name: 'solution1',
              title: '数字薄膜大棚',
              route: '/solution',
              picIndex: 0,
              type: 0,
              iconfont: 'icon-shuzibaomodapeng'
            },
            {
              idx: 2,
              name: 'solution2',
              title: '数字果园大田',
              route: '/solution',
              picIndex: 1,
              type: 1,
              iconfont: 'icon-shuziguoyuandatian'
            },
            {
              idx: 3,
              name: 'solution3',
              title: '数字玻璃温室',
              route: '/solution',
              picIndex: 2,
              type: 2,
              iconfont: 'icon-shuziboliwenshi'
            },
            {
              idx: 4,
              name: 'solution4',
              title: '数字水产',
              route: '/solution',
              picIndex: 3,
              type: 3,
              iconfont: 'icon-shuzishuichan'
            },
            {
              idx: 5,
              name: 'solution5',
              title: '数字无土栽培',
              route: '/solution',
              picIndex: 4,
              type: 4,
              iconfont: 'icon-shuziwutuzaipei'
            },
            {
              idx: 6,
              name: 'solution6',
              title: '草莓数字种植应用',
              route: '/solution',
              picIndex: 5,
              type: 5,
              iconfont: 'icon-caomeishuzizhongzhiyingyong'
            }
          ]
        },
        {
          name: 'product',
          title: '产品中心',
          route: '',
          children: [
            {
              idx: 7,
              name: 'product1',
              title: '环境通',
              route: '/product',
              picIndex: 6,
              type: 0,
              iconfont: 'icon-huanjingtong'
            },
            {
              idx: 9,
              name: 'product3',
              title: '智控中心',
              route: '/product',
              picIndex: 8,
              type: 2,
              iconfont: 'icon-zhikongxilie'
            },

            {
              idx: 10,
              name: 'product4',
              title: '综合气象站',
              route: '/product',
              picIndex: 9,
              type: 3,
              iconfont: 'icon-zongheqixiangzhan'
            },
            {
              idx: 8,
              name: 'product2',
              title: '水质监测浮漂',
              route: '/product',
              picIndex: 7,
              type: 1,
              iconfont: 'icon-shuizhijiancefupiao'
            },
            {
              idx: 13,
              name: 'product5',
              title: '采收宝',
              route: '/product',
              picIndex: 12,
              type: 4,
              iconfont: 'icon-caishoubao'
            }
          ]
        },

        {
          name: 'serviceCase',
          title: '服务案例',
          route: '',
          children: [
            {
              idx: 11,
              name: 'projectXinFeng',
              title: '鑫锋村',
              route: '/projectXinFeng',
              picIndex: 10,
              iconfont: 'icon-xinfengcun'
            },
            {
              idx: 12,
              name: 'projectFanJing',
              title: '范泾村',
              route: '/projectFanJing',
              picIndex: 11,
              iconfont: 'icon-fanjingcun'
            }
          ]
        },
        { name: 'about', title: '关于尚阳', route: '/about' }
      ],

      hoverImageList: [
        require(`@/assets/images/commonUse/hover2.png`),
        require(`@/assets/images/commonUse/hover1.png`),
        require(`@/assets/images/commonUse/hover3.png`),
        require(`@/assets/images/commonUse/hover4.png`),
        require(`@/assets/images/commonUse/hover5.png`),
        require(`@/assets/images/commonUse/hover6.png`),
        require(`@/assets/images/commonUse/hover7.png`),
        require(`@/assets/images/commonUse/hover8.png`),
        require(`@/assets/images/commonUse/hover9.png`),
        require(`@/assets/images/commonUse/hover10.png`),
        require(`@/assets/images/commonUse/hover11.png`),
        require(`@/assets/images/commonUse/hover12.png`),
        require(`@/assets/images/commonUse/hover13.png`)
      ],
      openflagm: false,
      collapseIndex: 0
    }
  },
  watch: {
    showMain: {
      handler(val, oval) {
        // console.log('val', val, oval)
        var mo = function (e) {
          e.preventDefault()
          // console.log('ee', e)
        }

        if (!val) {
          document.body.style.overflow = '' //出现滚动条
          document.removeEventListener('touchmove', mo, false)
        } else {
          document.body.style.overflow = 'hidden'
          document.addEventListener('touchmove', mo, false) //禁止页面滑动
        }
      },
      immediate: true
    },
    openflagm: {
      handler(val, oval) {
        var mo2 = function (e) {
          e.preventDefault()
        }
        let subItem = document.querySelector('.submenuMain-m')

        if (!val) {
          document.body.style.overflow = ''
          subItem.removeEventListener('touchmove', mo2, false)
        } else {
          document.body.style.overflow = 'hidden'
          subItem.addEventListener('touchmove', mo2, false)
        }
      },
      immediate: true
    }
  },
  created() {
    // console.log('1111', this.idx)
    this.showSub = false
    this.showMain = false
    this.openflagm = false
  },
  mounted() {
    // console.log('2222')
  },
  methods: {
    goChildren(item) {
      this.$router.push({
        path: item.route,
        query: { type: item.type ? item.type : 0 }
      })
    },

    openMenuChildren(e) {
      console.log('openMenuChildren', e)
      this.collapseIndex = e
      if (e === 0) {
        this.openflagm = false
        this.$router.push({
          path: '/'
        })
      }
      if (e === 4) {
        this.openflagm = false
        this.$router.push({
          path: '/about'
        })
      }
    },
    openModalm() {
      this.openflagm = !this.openflagm
      this.collapseIndex = this.index
      // if (this.collapseIndex === 0) {
      //   this.collapseIndex = 1
      // }
    },

    onHover(name, cindex) {
      this.hoverIndex = name
      // console.log('hover', name, cindex)
    },
    closeModal() {
      this.showSub = false
      this.showMain = false
    },
    clickFakeInput(mitem, index) {
      console.log('clickFakeInput', mitem, index)
      this.fakeIndex = index
      // console.log('index', index)
      if (mitem.children) {
        // console.log('假的儿子', mitem, index)
        this.showMain = true

        this.showSub = true
        this.submenuList = mitem.children
      } else {
        // console.log('假的')
        this.showMain = false

        this.showSub = false
        this.$router.push({
          path: mitem.route
        })
      }

      // this.$emit('clickNavChild', type)
    },
    clickInput(mitem, index) {
      console.log('clickInput', mitem, index)
      this.fakeIndex = index
      if (mitem.children) {
        // console.log('真的儿子')
        this.showMain = true

        this.showSub = true
        this.submenuList = mitem.children
      } else {
        this.showMain = false
        this.showSub = false
        this.$router.push({
          path: mitem.route
        })
      }

      // this.$emit('clickNavChild', type)
    },
    clickChildrenInput(citem) {
      console.log('clickChildrenInput', citem)
      this.showSub = false
      this.showMain = false

      this.$router.push({
        path: citem.route,
        query: { type: citem.type ? citem.type : 0 }
      })
      // this.$emit('clickNavChild', type)
    },
    clickLogo() {
      this.showMain = false
      this.showSub = false
      // console.log('首页')
      this.$router.push({
        path: '/'
        // path: '/test'
      })
    },
    goShop() {
      // window.location.href = 'http://www.baidu.com'
      window.open(
        'https://shop308456276.taobao.com/index.htm?spm=a1z10.5-c-s.w5001-24416968454.2.57d3602fcntCoO&scene=taobao_shop'
      )
    },
    openYunshang() {},
    openTaobao() {
      this.launchApp()
    },

    launchApp() {
      window.location.href =
        'https://shop308456276.taobao.com/index.htm?spm=a1z10.5-c-s.w5001-24416968454.2.57d3602fcntCoO&scene=taobao_shop'
      // https://itunes.apple.com/cn/app/id387682726
      // window.location.href =
      //   'taobao://shop308456276.taobao.com/index.htm?spm=a1z10.5-c-s.w5001-24416968454.2.57d3602fcntCoO&scene=taobao_shop'
      // window.open(

      // )
    }
  }
}
</script>

<style lang="scss" scoped>
.navHeaderWrapper {
  width: 100%;
  // height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}

.navHeader {
  padding: 0 360px 0 360px;
  box-sizing: border-box;
  // position: fixed;
  // top: 0;
  // left: 0;
  // z-index: 100;
  width: 100%;

  display: flex;
  justify-content: center;
  // background: rgba(51, 51, 51, 0.7);
  background: #000000;
  opacity: 0.95;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  .mainmenu {
    position: relative;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    padding: 0;

    .leftMenuList {
      display: flex;
      width: 33%;
      .leftMenuItem {
        color: #fff;
        font-size: 12px;
        font-weight: normal;
        margin: 0 40px 0 0;
        // padding: 0 10px 0 10px;
        // position: relative;
        height: 60px;
        line-height: 60px;
        flex: none;
        cursor: pointer;
        .leftMenuItemTitle {
          height: 44px;
        }
      }
    }
    .leftMenuList-m {
      width: 33%;
      i {
        color: #fff;
        cursor: pointer;
      }
    }
    @media only screen and (min-width: 1200px) {
      .leftMenuList {
      }
      .leftMenuList-m {
        display: none !important;
      }
    }
    @media only screen and (max-width: 1199px) {
      .leftMenuList {
        display: none !important;
      }
      .leftMenuList-m {
      }
    }

    .logoBox {
      height: 60px;
      width: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .logo-light {
        height: 20px;
        width: 20px;
      }
    }
    .rightMenuList {
      position: relative;
      display: flex;
      width: 33%;
      justify-content: flex-end;
      .rightMenuItem {
        color: #fff;
        font-size: 12px;
        font-weight: normal;
        // margin: 0 40px 0 0;
        // padding: 0 10px 0 10px;
        // position: relative;
        height: 60px;
        line-height: 60px;
        flex: none;
        cursor: pointer;
        .rightMenuItemTitle {
          height: 44px;
        }

        .qrCodeBox {
          z-index: 101;
          position: absolute;
          top: 60px;
          left: -20px;
          height: 120px;
          width: 120px;
          transform: scale(0);
          opacity: 0;
          -webkit-transition: all 0.4s ease-in-out;
          -o-transition: all 0.4s ease-in-out;
          transition: all 0.4s ease-in-out;
          img {
            height: 100%;
            width: 100%;
          }
        }
      }
      .rightMenuItem:hover .qrCodeBox {
        transform: scale(1);
        opacity: 1;
      }
    }
    @media only screen and (max-width: 1199px) {
      .rightMenuList {
        .rightMenuItem {
          display: none;
        }
      }
    }
    .closePosition1 {
      color: #fff;
      position: absolute;
      top: 22px;
      left: 410px;
      cursor: pointer;
    }
  }
  @media only screen and (max-width: 1199px) {
    .mainmenu {
      height: 44px;
    }
  }
}
@media only screen and (max-width: 1429px) {
  .navHeader {
    padding: 0 200px 0 200px;
  }
  .submenuMain {
    .submenuLeft {
      padding-left: 200px !important;
    }
  }
}
@media only screen and (min-width: 1200px) {
  .navHeader {
  }
}
@media only screen and (max-width: 1199px) {
  .navHeader {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
    padding: 0 20px 0 20px;
  }
}
.submenuMain {
  display: flex;
  width: 100%;
  // margin-top: 60px;
  height: calc(100vh - 60px);
  // height: 100%;
  .submenuLeft {
    background: #000000;
    opacity: 0.95;
    // background: rgba(51, 51, 51, 0.7);
    // backdrop-filter: blur(10px);
    padding-left: 360px;
    color: #fff;
    font-weight: 100;
    font-size: 20px;
    // position: absolute;
    // top: 60px;
    // left: 0;
    width: 41.6%;
    height: 100%;

    // box-sizing: content-box !important;
    visibility: visible;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    .submenuItemBox {
      overflow-x: hidden;
      overflow-y: auto;
      width: 100%;
      .submenuItem {
        cursor: pointer;

        height: 54px;
        line-height: 54px;
        font-size: 14px;
        display: block;
        transition: 0s;
        color: #fff;
        // margin: 0 20px;
        box-sizing: border-box;
        .iconfont {
          font-size: 18px;
        }
        &:hover {
          border-bottom: 1px solid #f8d86a;
        }
        &.active {
          border-bottom: 1px solid #f8d86a;
        }
      }
    }
  }
  .submenuRight {
    width: 58.4%;
    height: 100%;
    background: #000000 !important;
    opacity: 1 !important;
    img {
      background: #000000 !important;
      opacity: 1 !important;
      width: 100%;
      height: 100%;
    }
  }
}
.submenuMain-m {
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  height: 100vh;
  width: 100%;
  color: #fff;

  .header-menu {
    // background: #ccc;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100vh;
    box-sizing: border-box;
    padding-left: 20px;
    // padding-top: 14px;
    .header-menu-item {
      margin: 10px 0 10px 0;
      cursor: pointer;
      .header-menu-item-inner {
        display: flex;
        .menuBigTitle {
          font-weight: 100;
          font-size: 20px;
        }
      }
      .header-menu-item-sub-items {
        .header-menu-sub-item {
          margin: 6px 0 6px 31px;
          cursor: pointer;
          .header-menu-sub-item-text {
            // color: #000;
            font-weight: 100;
            font-size: 16px;
            box-sizing: border-box;
            line-height: 40px;
          }
          .active {
            border-bottom: 1px solid #f8d86a;
          }
        }
      }
      .hide {
        display: none;
      }
    }
  }
}
</style>
