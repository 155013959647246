<template>
  <div class="home" :style="{ '--vh': vh + 'px' }">
    <!-- 模拟头部 -->
    <!-- <header :class="isShowAnimation ? 'relative' : ''"></header> -->
    <navHeader :index="-1"></navHeader>
    <header :class="isShowAnimation ? 'relative' : ''"></header>
    <!-- 存放图片的大盒子 -->
    <div class="content" id="content">
      <!-- 模拟图片元素的盒子 -->
      <div class="cover-wrapper" :style="{ paddingBottom: getpbottom + 'px' }">
        <!-- 固定定位的盒子主要是让cover元素固定在页面最上层,判断滚动到位置了 -->
        <div
          class="cover-container"
          :style="{
            transform: 'translate3d(' + '0px' + ',' + (isShowAnimation ? 0 : getpbottom - vh) + 'px' + ',' + '0px)'
          }"
          :class="isFixed && isShowAnimation ? 'flex' : ''"
        >
          <!-- 放置图片的盒子，监控滚轮事件修改高度动态显示图片 -->
          <div class="cover-box" :style="{ zIndex: '4', height: '100%' }">
            <div class="detail">
              <picture>
                <!-- <picture :style="{ height: changenum + '%' }"> -->
                <!-- 展示图片1 -->
                <img
                  class="cover-pic"
                  src="https://img1.baidu.com/it/u=1865282318,2874149192&fm=253&fmt=auto&app=138&f=JPEG?w=800&h=500"
                  alt=""
                />
              </picture>
              <div
                class="cover-text"
                :style="{
                  transform: 'translate3d(' + '0px' + ',' + textBoxTranslate1 + 'px' + ',' + '0px)'
                }"
              >
                111数字果园以大数据、云计算、物联网等新一代信息技术为支撑，深度融合了种植、养殖、加工等诸多环节的数据管理与应用，优化农业生产过程，提高资源利用效率，实现精准农业、高效农业、可持续农业。
              </div>
            </div>
          </div>
          <div class="cover-box" :style="{ zIndex: '3', height: '100%' }">
            <div class="detail">
              <picture>
                <!-- 展示图片2 -->
                <img
                  class="cover-pic"
                  src="https://img1.baidu.com/it/u=1614235705,3930371442&fm=253&fmt=auto&app=120&f=JPEG?w=835&h=500"
                  alt=""
                />
              </picture>
              <div
                class="cover-text textTranslate"
                :style="{
                  transform: 'translate3d(' + '0px' + ',' + textBoxTranslate2 + 'px' + ',' + '0px)'
                }"
              >
                222数字薄膜大棚作为一种新兴的农业形态，随着城市规划对绿地面积的限制和生产能力的提高，其逐渐被越来越多的人所接受和运用。其通过设备自动控制、信息互联等手段，实现了农业生产的数字化和智能化，同时也提升了农业生产的效率和质量。
              </div>
            </div>
          </div>
          <!-- <div class="cover-box" :style="{ zIndex: '2', height: '100%' }">
            <div class="detail">
              <picture>
              
                <img
                  class="cover-pic"
                  src="https://img0.baidu.com/it/u=3526761496,3758949724&fm=253&fmt=auto&app=138&f=JPEG?w=667&h=500"
                  alt=""
                />
              </picture>
              <div
                class="cover-text"
                :style="{
                  transform: 'translate3d(' + '0px' + ',' + textBoxTranslate3 + 'px' + ',' + '0px)'
                }"
              >
                图片3
              </div>
            </div>
          </div> -->
          <!-- <div class="cover-box" :style="{ zIndex: '1', height: '100%' }">
            <div class="detail">
              <picture>
                
                <img
                  class="cover-pic"
                  src="https://img0.baidu.com/it/u=3016247431,198114131&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=313"
                  alt=""
                />
              </picture>
              <div class="cover-text">图片4</div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <footer></footer>
  </div>
</template>
 
<script>
import navHeader from '@/components/navHeader.vue'
export default {
  components: { navHeader },
  data() {
    return {
      itemHeight: 0, //获取滚动元素的实际高度
      vh: 100,
      pbottom: 0,
      isShowAnimation: false,
      isFixed: false,
      num: 0,
      one: '0%',
      textBoxTranslate1: 0,
      textBoxTranslate2: 110,
      textBoxTranslate3: 0
    }
  },
  mounted() {
    //获取页面高度
    this.vh = (document && document.documentElement && document.documentElement.clientHeight) || window.innerHeight

    //监听窗口缩放
    window.addEventListener('resize', () => {
      this.vh = (document && document.documentElement && document.documentElement.clientHeight) || window.innerHeight
    })
    //监听滚动条事件
    window.addEventListener('scroll', this.getDomHeight)
  },
  computed: {
    getpbottom() {
      this.pbottom = this.vh * 2
      return this.pbottom
    },
    gettran() {
      this.num = this.pbottom - this.vh
      return this.num
    },
    changenum() {
      return this.one
    }
  },
  methods: {
    //获取元素高度
    getDomHeight(e) {
      //核心代码
      var scrollT = document.documentElement.scrollTop || document.body.scrollTop //滚动条距离顶部的距离
      // console.log(scrollT);
      const target = document.querySelector('#content') //获取dom元素
      const top = document.querySelector('#content').offsetTop //距离body顶部的距离

      const clientRect = target.getBoundingClientRect() //获取元素的定位信息
      // let items = document.querySelectorAll('.cover-box')
      let items = document.querySelectorAll('.cover-pic')
      let items2 = document.querySelectorAll('.cover-text')

      if (scrollT >= this.vh + 60) {
        this.isFixed = true
        if (scrollT >= this.vh + 60 && this.vh * 2 + 60 >= scrollT) {
          this.textBoxTranslate1 = ((scrollT - (this.vh + 60)) / this.vh) * 200
          this.textBoxTranslate2 = -((scrollT - (this.vh + 60)) / this.vh) * 200
          // console.log('this.textBoxTranslate1', this.textBoxTranslate1)
          // console.log('this.textBoxTranslate2', this.textBoxTranslate2)
          if (this.textBoxTranslate2 < -100) {
            this.textBoxTranslate2 = -100
          }
          if (this.textBoxTranslate1 > 100) {
            this.textBoxTranslate1 = 100
          }
        }
        // if (scrollT >= this.vh * 2 + 60 && this.vh * 3 + 60 >= scrollT) {
        //   this.textBoxTranslate2 = -((scrollT - (this.vh * 2 + 60)) / this.vh) * 200
        //   console.log('this.textBoxTranslate2', this.textBoxTranslate2)
        // }
      } else {
        this.isFixed = false
      }

      // console.log('clientRect.top', clientRect.top)
      // console.log('window.innerHeight', window.innerHeight)
      if (clientRect.top <= window.innerHeight) {
        console.log('scrollT', scrollT)

        // console.log('this.vh', this.vh)

        // if (scrollT >= this.vh) {

        //盒子距离窗口顶部的距离小于窗口高度证明进入可视区域
        // console.log('移入可视区域');
        this.isShowAnimation = true //进入可视窗口的时候，把轮播图盒子固定定位在页面上
        this.one =
          ((window.innerHeight - clientRect.top) / window.innerHeight) * 100 < 100
            ? ((window.innerHeight - clientRect.top) / window.innerHeight) * 100
            : 100 //在轮播进入的时候第一个图片高度逐渐变大
        //第一个盒子高度降低
        // console.log('这是第二个盒子');
        items[0].style.height =
          100 - ((scrollT - top) / window.innerHeight) * 200 < 0
            ? '0%'
            : 100 - ((scrollT - top) / window.innerHeight) * 200 > 100
            ? '100%'
            : 100 - ((scrollT - top) / window.innerHeight) * 200 + '%'

        // console.log('这是第三个盒子');
        // items[1].style.height =
        //   100 - ((scrollT - top - this.vh) / window.innerHeight) * 200 < 0
        //     ? '0%'
        //     : 100 - ((scrollT - top - this.vh) / window.innerHeight) * 200 > 100
        //     ? '100%'
        //     : 100 - ((scrollT - top - this.vh) / window.innerHeight) * 200 + '%'
        // console.log('这是第4个盒子');
        // items[2].style.height =
        //   100 - ((scrollT - top - this.vh - this.vh) / window.innerHeight) * 200 < 0
        //     ? '0%'
        //     : 100 - ((scrollT - top - this.vh - this.vh) / window.innerHeight) * 200 > 100
        //     ? '100%'
        //     : 100 - ((scrollT - top - this.vh - this.vh) / window.innerHeight) * 200 + '%'

        items2[0].style.opacity =
          100 - ((scrollT - top) / window.innerHeight) * 200 < 0
            ? '0%'
            : 100 - ((scrollT - top) / window.innerHeight) * 200 > 50
            ? '100%'
            : 100 - ((scrollT - top) / window.innerHeight) * 200 + '%'

        items2[1].style.opacity =
          100 - ((scrollT - top) / window.innerHeight) * 200 < 0
            ? '100%'
            : 100 - ((scrollT - top) / window.innerHeight) * 200 > 50
            ? '0%'
            : ((scrollT - top) / window.innerHeight) * 200 + '%'
        // console.log('这是第4个盒子');
        // items2[2].style.opacity =
        //   100 - ((scrollT - top - this.vh - this.vh) / window.innerHeight) * 200 < 0
        //     ? '0%'
        //     : 100 - ((scrollT - top - this.vh - this.vh) / window.innerHeight) * 200 > 100
        //     ? '100%'
        //     : 100 - ((scrollT - top - this.vh - this.vh) / window.innerHeight) * 200 + '%'

        this.textBoxTranslate = 100 - ((scrollT - top - this.vh - this.vh) / window.innerHeight) * 200 + '%'
        // console.log('this.textBoxTranslate', this.textBoxTranslate)
      }
      //盒子距离窗口顶部的距离大于盒子高度减去最后一屏的高度，这个时候到了最后一个图片需要将图片定位偏移
      if (Math.abs(clientRect.top) > clientRect.height - window.innerHeight || clientRect.top > window.innerHeight) {
        this.isShowAnimation = false
        // console.log('移出可视区域');
      }
    }
  }
}
</script>
 
<style lang="scss" scoped>
img {
  display: block;
}

.relative {
  // position: relative;
  // z-index: 10;
}

.tran {
  transform: translate3d(0px, 2811px, 0px);
}

.home {
  height: 100%;
  width: 100%;
  padding-top: 60px;
  box-sizing: border-box;
  // background: green;
  header {
    height: 100vh;
    width: 100%;
    background: pink;
  }

  .content {
    .cover-wrapper {
      .flex {
        position: fixed !important;
        top: 0;
        left: 0;
      }
      .cover-container {
        height: var(--vh);
        left: 0;
        right: 0;
        position: absolute;

        .cover-box {
          height: var(--vh);
          left: auto;
          position: absolute;
          right: 0;
          top: 0;
          width: 100%;
          overflow: hidden;

          .detail {
            height: var(--vh);
            left: auto;
            position: absolute;
            right: 0;
            top: 0;
            width: 100vw;

            picture {
              bottom: 7.41vh;
              height: 83.2vh;
              // height: var(--vh);
              left: 4.2vw;
              position: absolute;
              width: 50vw;
              overflow: hidden;
              img {
                display: block;
                width: 50vw;
                height: 83.2vh;
                object-fit: cover;
              }
            }
            .cover-text {
              width: 440px;
              height: 200px;
              // background: #fff;
              color: brown;
              bottom: 30vh;
              // opacity: 0;
              // height: var(--vh);
              left: 58.6vw;
              position: absolute;
            }
            .textTranslate {
              bottom: 20vh;
            }
          }
        }
      }
    }
  }

  footer {
    height: 1200px;
    background: red;
  }
}
</style>